import React from 'react';

const Layout = ({ children }) => {
  return (
    <>
      <div className="font-normal bg-stone-900 text-white" style={{ fontFamily:"Josefin Sans" }}>
      {/* <div className="font-normal" style={{ fontFamily:"Newsreader" }}> */}
      {/* <div className="font-sans"> */}
        {children}
      </div>
    </>
  );
};

export default Layout;
